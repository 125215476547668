/**
 * Diese Componente ist für folgende Aufgaben zuständig:
 *
 * – sie registriert das mhPageBuilder-Vuex-Store-Module
 * – mapped alle getters, mutations and actions
 * – empfängt alle events die via EventBus emitted werden und verändert entsprechend den state
 *
 */

import EventBus from '@/helper/EventBus.js'
import store from "../store/store.js";
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
	props: {},
	data() {
		return {};
	},
	watch: {},
	computed: {
		...mapGetters({
			selectedItemsIds : 'mhPageBuilder/selectedItemsIds',
			pages : 'mhPageBuilder/pages',
			getPage : 'mhPageBuilder/getPage',
			isItemSelected : 'mhPageBuilder/isItemSelected',
			isItemHovered : 'mhPageBuilder/isItemHovered',
		}),
		...mapState({
			state : 'mhPageBuilder',
		}),
	},
	methods: {
		XXXXsomeFunction( doLog = true ){
			const layoutId = this.layoutId
			const path = this.path
			const type = this.data.type
			const id = this.data.id

			// groupCollapsed group
			if( doLog ){
				console.groupCollapsed( this.$options.name, '• onClick()' )
				console.log('layoutId:', layoutId)
				console.log('path:', path)
				console.log('type:', type)
				console.log('id:', id)
				console.groupEnd()
			}
		},
	},
	created(){
		const alreadyRegistered = this.$store.state['mhPageBuilder'] ? true : false
		if( !alreadyRegistered ) this.$store.registerModule("mhPageBuilder", store)
	},
	mounted(){
		//console.log('StoreRead.mixin.js • mounted')
	},
	destroyed(){},
}
