<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgEditorRow></DgEditorRow>

	2021-09-24	init

-->

<template>
	<div class="DgEditorRow" :class="elmClasses">
		<mark>DgEditorRow</mark>
		<button @click="addCell">Add Cell</button>
		<pre name="row">{{row}}</pre>
		<pre name="elmInnerStyles">{{elmInnerStyles}}</pre>

		<div class="DgEditorRow__inner" :style="elmInnerStyles">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'DgEditorRow',
		components: {},
		mixins: [],
		props: {
			row: {
				type     : [Object],
				default  : ()=>{ return {} },
				required : false,
			},
			rowIndex: {
				type     : [Number],
				default  : 0,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
					//console.log('to', to)
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			elmInnerStyles(){
				let styles = {}

				const styleProps = this._.get( this.row, 'props.styles', {} )

				styles.display = 'grid'
				styles.gridTemplateColumns = 'repeat(' + this.cells.length + ', 1fr)'

				return styles
			},
			cells(){
				const cells = this._.get( this.row, 'cells' )
				//console.log( cells )
				//return cells
				return this._.isArray( cells ) ? cells : []
			},
		},
		methods: {
			addCell( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• addCell()' )
					//console.log('key:', value)
					console.groupEnd()
				}

				EventBus.$emit('addCell', {
					rowIndex : this.rowIndex
				})
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgEditorRow { // debug
		[showBorders2] & {}
	}
	.DgEditorRow { // layout
		&__inner {
			//display: grid;
			//grid-template-columns: repeat(3, 1fr);
  			//gap: 10px;
		}
	}
	.DgEditorRow { // styling
		padding: 10px;
		background-color: lighten( red, 35 );
		outline: 1px solid red;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
