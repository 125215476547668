<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<PageContainer></PageContainer>

	2021-09-24	init

-->

<template>
	<div class="PageContainer" :class="elmClasses"
		@click.stop="eventBusEmit('mhPageBuilder/containerClick', {
			event : $event,
			item : page,
		})">
		<ContainerGui
			:label="'PageContainer • ' + page.id"
			:isVisibleAddOnTopBtn="false"
			:isVisibleAddOnRightBtn="false"
			:isVisibleAddOnBottomBtn="false"
			:isVisibleAddOnLeftBtn="false"
		></ContainerGui>

		<div class="PageContainer__content">
			<LayoutContainer
				:pageId="_pageId"
				@click.native.stop="eventBusEmit('mhPageBuilder/containerClick', {
					event : $event,
					item : layout,
					pageId : _pageId,
				})">
				<SectionContainer v-for="( section, sectionIndex ) in _.get( layout, 'sections')" :key="'s'+sectionIndex"
					:pageId="_pageId"
					:data="section"
					:path="`layouts[0].sections[${sectionIndex}]`"
					:isSelected="isItemSelected( section.id )"
					:isHovered="isItemHovered( section.id )"
					:isGuiVisible="true"
					@click.native.stop="eventBusEmit('mhPageBuilder/containerClick', {
						event : $event,
						item : section,
						path : `layouts[0].sections[${sectionIndex}]`,
						pageId : _pageId,
					})"
					XXXmouseenter.native="onContainerMouseEnter( $event, layout, section )"
					XXXmouseleave.native="onContainerMouseLeave( $event, layout, section )">
					<GridContainer v-for="( grid, gridIndex ) in _.get( section, 'grids')" :key="'g'+gridIndex"
						:pageId="_pageId"
						:data="grid"
						:path="`layouts[0].sections[${sectionIndex}].grids[${gridIndex}]`"
						:isSelected="isItemSelected( grid.id )"
						:isHovered="isItemHovered( grid.id )"
						:isGuiVisible="true"
						@click.native.stop="eventBusEmit('mhPageBuilder/containerClick', {
							event : $event,
							item : grid,
							path : `layouts[0].sections[${sectionIndex}].grids[${gridIndex}]`,
							pageId : _pageId,
						})"
						XXXclick.native.stop="onContainerClick( $event, layout, section, grid )"
						XXXmouseenter.native="onContainerMouseEnter( $event, layout, section, grid )"
						XXXmouseleave.native="onContainerMouseLeave( $event, layout, section, grid )">
						<CellContainer v-for="( cell, cellIndex ) in _.get( grid, 'cells')" :key="'c'+cellIndex"
							:pageId="_pageId"
							:data="cell"
							:path="`layouts[0].sections[${sectionIndex}].grids[${gridIndex}].cells[${cellIndex}]`"
							:isSelected="isItemSelected( cell.id )"
							:isHovered="isItemHovered( cell.id )"
							:isGuiVisible="true"
							@click.native.stop="eventBusEmit('mhPageBuilder/containerClick', {
								event : $event,
								item : cell,
								path : `layouts[0].sections[${sectionIndex}].grids[${gridIndex}].cells[${cellIndex}]`,
								pageId : _pageId,
							})"
							XXXclick.native.stop="onContainerClick( $event, layout, section, grid, cell )"
							XXXmouseenter.native="onContainerMouseEnter( $event, layout, section, grid, cell )"
							XXXmouseleave.native="onContainerMouseLeave( $event, layout, section, grid, cell )">
						</CellContainer>
					</GridContainer>
					<!--
					-->
				</SectionContainer>
			</LayoutContainer>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import ContainerMixin from './mixins/Container.mixin.js'
	import ContainerGui from './ContainerGui.vue'
	import StoreReadMixin from './mixins/StoreRead.mixin.js'
	import LayoutContainer from './LayoutContainer.vue'
	import SectionContainer from './SectionContainer.vue'
	import GridContainer from './GridContainer.vue'
	import CellContainer from './CellContainer.vue'

	export default {
		name: 'PageContainer',
		components: {
			ContainerGui,
			LayoutContainer,
			SectionContainer,
			GridContainer,
			CellContainer,
		},
		mixins: [
			ContainerMixin,
			StoreReadMixin,
		],
		props: {
			page: {
				type : [Object],
				default : ()=> { return {} },
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler : function( to, from ){},
				immediate : true,
				deep : true,
			},
		},
		computed: {
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			_pageId(){
				return this._.get( this.page, 'id' )
			},
			layout(){
				return this._.get( this.page, 'layouts[0]' )
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
			onContainerClick(){},
			onContainerMouseEnter(){},
			onContainerMouseLeave(){},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.PageContainer { // debug
		[showBorders2] & {}
	}
	.PageContainer { // vars
		--gui-primaryColor: darken( #684D07, 75 );
	}
	.PageContainer { // layout
		position: relative;
	}
	.PageContainer { // styling
		&__content { padding: 20px; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
