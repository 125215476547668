<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<PagebuilderApp></PagebuilderApp>

	2021-09-24	init

-->

<template>
	<div class="PagebuilderApp" :class="elmClasses">
		<div class="PagebuilderApp__content">
			<mark>PagebuilderApp</mark><hr>

			<PageContainer
				v-for="(page, index) in pages" :key="'p'+index"
				:page="page"
			></PageContainer>

			<div style="display: flex;">
				<pre name="state" maxheight>{{state}}</pre>
				<pre name="pages" maxheight>{{pages}}</pre>
				<pre name="getPage('page-1')" maxheight>{{getPage('page-1')}}</pre>
			</div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import StoreReadMixin from './mixins/StoreRead.mixin.js'
	import StoreWriteMixin from './mixins/StoreWrite.mixin.js'
	import PageContainer from './PageContainer.vue'

	export default {
		name: 'PagebuilderApp',
		components: {
			PageContainer,
		},
		mixins: [
			StoreReadMixin,
			StoreWriteMixin,
		],
		props: {},
		data(){
			return {}
		},
		watch: {},
		computed: {
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			onKeydown( e, doLog = true ){
				EventBus.$emit('mhPageBuilder/keydown', {
					event : e
				})
			},
		},
		created(){
			window.addEventListener('keydown', this.onKeydown )
		},
		mounted(){},
		destroyed(){
			window.removeEventListener('keydown', this.onKeydown )
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.PagebuilderApp { // debug
		[showBorders2] & {}
		background-color: darken( yellow, 10 );
	}
	.PagebuilderApp { // vars
		--sampleVar : red;
	}
	.PagebuilderApp { // layout
		position: relative;
	}
	.PagebuilderApp { // styling
		&__content { padding: 20px; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
