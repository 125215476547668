//import inViewportMixin from './vue-in-viewport-mixin'
import EventBus from '@/helper/EventBus.js'
import { clone } from 'lodash'

export default {
	mixins : [],
	props : {
		pageId: {
			type     : [String],
			default  : '',
			required : false,
		},
		data: {
			type     : [Object],
			default  : ()=> { return {} },
			required : false,
		},
		path: {
			type     : [String],
			default  : '',
			required : false,
		},
		isSelected: {
			type     : [Boolean],
			default  : false,
			required : false,
		},
		isHovered: {
			type     : [Boolean],
			default  : false,
			required : false,
		},
		isGuiVisible: {
			type     : [Boolean],
			default  : false,
			required : false,
		},
	},
	data() {
		return {};
	},
	watch : {},
	computed : {
		parentPath(){
			const path = this.path
			const pathParts = path.split('.')
			const parentPath = pathParts.slice(0, -1).join('.')

			/*
			console.log('parentPath')
			console.log('path:', path)
			console.log('pathParts:', pathParts)
			console.log('parentPath:', parentPath)
			console.log('')
			*/

			return parentPath
		},
		index(){
			const path = this.path
			const matches = []
			path.replace(/\[(.+?)\]/g, function($0, $1) { matches.push($1) })
			const index = matches.pop()

			/*
			console.log('index')
			console.log('path:', path)
			console.log('matches:', matches)
			console.log('index:', index)
			console.log('')
			*/

			return index
		},
	},
	methods: {
		XXXonClick( doLog = true ){
			const layoutId = this.layoutId
			const path = this.path
			const type = this.data.type
			const id = this.data.id

			// groupCollapsed group
			if( doLog ){
				console.groupCollapsed( this.$options.name, '• onClick()' )
				console.log('layoutId:', layoutId)
				console.log('path:', path)
				console.log('type:', type)
				console.log('id:', id)
				console.groupEnd()
			}
		},
		XXXonDoubleClick( doLog = true ){
			const layoutId = this.layoutId
			const path = this.path
			const type = this.data.type
			const id = this.data.id

			// groupCollapsed group
			if( doLog ){
				console.groupCollapsed( this.$options.name, '• onDoubleClick()' )
				console.log('layoutId:', layoutId)
				console.log('path:', path)
				console.log('type:', type)
				console.log('id:', id)
				console.groupEnd()
			}

			EventBus.$emit('selectItem', {
				layoutId : layoutId,
				path : path,
				type : type,
				id : id,
			}, false)
		},
		eventBusEmit( type, payload ){
			EventBus.$emit( type, payload )
		},
	},
	mounted(){},
	destroyed(){},
}
