import { find } from 'lodash'

export default {
	namespaced: true,
	state: {
		pages : [
			{	id : 'page-1',
				type : 'page',
				layout : {
					id : 'layout-1',
					type : 'layout',
					sections : [
						{	id : 'section-1',
							type : 'section',
							grids : [
								{
									id : 'grid-1',
									type : 'grid',
									cells : [
										{ id : 'cell-1', type : 'cell', modules : [], },
										{ id : 'cell-2', type : 'cell', modules : [],},
										{ id : 'cell-3', type : 'cell', modules : [],},
									],
								},
								{
									id : 'grid-2',
									type : 'grid',
									cells : [
										{ id : 'cell-4', type : 'cell', modules : [], },
										{ id : 'cell-5', type : 'cell', modules : [], },
									],
								},
							],
						},
						{	id : 'section-2',
							type : 'section',
							grids : [
								{
									id : 'grid-3',
									type : 'grid',
									cells : [
										{ id : 'cell-6', type : 'cell', modules : [], },
									],
								},
							],
						},
						{	id : 'section-3',
							type : 'section',
							grids : [
								{
									id : 'grid-4',
									type : 'grid',
									cells : [
										{ id : 'cell-7', type : 'cell', modules : [], },
										{ id : 'cell-8', type : 'cell', modules : [], },
									],
								},
							],
						},
					],
				},
			},
			{	id : 'page-2',
				type : 'page',
				layout : {},
			},
		],
		selectedItems : [
			'section-1',
			'section-2',
		],
		history : [],
	},
	getters : {
		getPage: ( state, getters ) => ( pageId )=>{
			return find( state.pages, { id : pageId } ) //.filter((o)=>{ return o.pageId === pageId})
		},
	},
	mutations: {
		XXXsetItemProp( state, payload, doLog = false ) {
			const fieldName = payload.fieldName
			const key = payload.key
			const value = payload.value
			const field = state.fields[ fieldName ]

			// groupCollapsed group
			if (doLog) {
				console.group('store.js', '• setFieldProp()')
				console.log('payload:', payload)
				console.log('fieldName:', fieldName)
				console.log('key:', key)
				console.log('value:', value)
				console.log('field:', field)
				console.groupEnd()
			}

			/*
			Vue.set(field, key, value)
			if( 'subadults' === fieldName ) {
				console.log( '•••' )
				//fetch( state.restBase +  )
			}
			*/
		},
	},
	actions: {}
}
