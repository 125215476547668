<template>
	<MhRouterView class="DevView view hSpace hSpace--app" :isContentReady="isContentReady">

		<div class="view__inner">

			<br /><strong>DevView.vue</strong><hr /><br />

			<br/><br/><mark class="block">
				MhPageBuilder/v2<br/>
			</mark><br/>
			<PagebuilderApp></PagebuilderApp>

			<br/><br/><mark class="block">
				MhPageBuilder/v1<br/>
			</mark><br/>
			<LayoutContainer></LayoutContainer>

			<br/><br/><mark class="block">
				DgEditor<br/>
			</mark><br/>

			<DgEditor
				:layout="dgEditorLayout"
			></DgEditor>
			<!--
			-->

			<br/><br/><mark class="block">
				DgVideoPlayer with autoplay prop.<br/>
			</mark><br/>
			<DgVideoPlayer
				:file="sampleMp4"
				:isAutoplay="true"
				:text="'text-123'"
				:copyright="'copyright-456'"
			></DgVideoPlayer>

			<br/><br/><mark class="block">
				DgVideoPlayer with loop and autoplay prop.<br/>
			</mark><br/>
			<DgVideoPlayer
				:file="sampleMp4"
				:isAutoplay="true"
				:isLoop="true"
				:text="'text-123'"
				:copyright="'copyright-456'"
			></DgVideoPlayer>

			<br/><br/><mark class="block">
				DgText with MhSlideToggle example.<br/>
			</mark><br/>
			<DgText
				:fontSize="'xl'"
				:hasIndent="false"
				:isFolded="true"
				:text="`
					Beispieltext1<br/>
					Beispieltext2<br/>
					Beispieltext3<br/>
					Beispieltext4<br/>
					Beispieltext5<br/>
					Beispieltext6<br/>
				`"
			></DgText>

			<br/><br/><mark class="block">
				MhSlideToggle example.<br/>
			</mark><br/>
			<button @click="doSlideToggle = !doSlideToggle">Do MhSlideToggle</button>
			<pre name="doSlideToggle">{{doSlideToggle}}</pre>
			<p>MhSlideToggle without minHeight</p><br/>
			<MhSlideToggle :isOpen="doSlideToggle" @stateChange="onSlideToggleStateChange">
				1<br/>
				2<br/>
				3<br/>
				4<br/>
				5<br/>
				6<br/>
			</MhSlideToggle>
			<hr/>
			<p>With with minHeight="'3.5em'"</p><br/>
			<MhSlideToggle :isOpen="doSlideToggle" :minHeight="'3.5em'">
				1<br/>
				2<br/>
				3<br/>
				4<br/>
				5<br/>
				6<br/>
			</MhSlideToggle>

			<br/><br/><mark class="block">
				zoom-on-hover example.<br/>
			</mark><br/>

			<div style="width:400px">
				<zoom-on-hover
					img-normal="https://loremflickr.com/640/480/dog"
					img-zoom="https://loremflickr.com/640/480/dog"
					:scale="1.5"
					:disabled="false"
  				></zoom-on-hover>
			</div>

			<br/><br/><mark class="block">
				Überblick über diverse Components.<br/>
				Aktiviere Debug-Outlines via "1" um die Boundries zu sehen.
			</mark><br/>

			<br/>

			<DgText
				:fontSize="'m'"
				:hasIndent="false"
				:text="'Beispieltext<br/>1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9<br/>10'"
			></DgText>

			<hr/>

			<DgAudioPlayer
				:file="sampleMp3"
				:text="'Mein fabelhafter Text'"
			></DgAudioPlayer>

			<hr/>

			<DgAudioPlayer
				:file="sampleMp3"
				:text="''"
			></DgAudioPlayer>

			<hr/>

			<DgPlusTextButton
				:title="'Marios Sample Title'"
			></DgPlusTextButton>

			<hr/>

			<DgButton
				:type="'menu'"
				:text="$t('menu')"
				@click.native="onClickMenuBtn('menu')"
			></DgButton>

			<hr/>

		</div>

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'
	import MhSlideToggle from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhSlideToggle/v1/MhSlideToggle.vue'

	import DgAudioPlayer from '@/components/contentModules/DgAudioPlayer.contentModule.vue'
	import DgPlusTextButton from '@/components/molecules/DgPlusTextButton.vue'
	import DgText from '@/components/contentModules/DgText.contentModule.vue'
	import DgVideoPlayer from '@/components/contentModules/DgVideoPlayer.contentModule.vue'
	import DgEditor from '@/components/editor/DgEditor.vue'
	import DgButton from '@/components/molecules/DgButton.vue'

	import LayoutContainer from '@/components/MhPageBuilder/v1/LayoutContainer.vue'
	import PagebuilderApp from '@/components/MhPageBuilder/v2/PagebuilderApp.vue'

	/*
	import Guide from '@/classes/Guide.work-in-progress.js'
	const guide = new Guide('kunst-fuer-tiere-de')
	console.log( guide )
	*/

	export default {
		name: 'DevView',
		components: {
			MhRouterView,
			DgAudioPlayer,
			DgPlusTextButton,
			DgButton,
			MhSlideToggle,
			DgText,
			DgVideoPlayer,
			DgEditor,
			LayoutContainer,
			PagebuilderApp,
		},
		mixins: [ restHandler ],
		directives: {},
		props: {},
		data(){
			return {
				doSlideToggle : false,
				isContentReady: false,
				sampleMp3 : {
					url : 'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3',
				},
				sampleMp4 : {
					url : 'http://digiguide.local/wp-content/uploads/2021/10/flower.mp4',
				},
				dgEditorLayout : {
					rows : [
						{
							props : {
								styles : {},
							},
							cells : [
								{},
								{},
								{},
							]
						},
						{
							cells : [
								{},
							]
						},
						{
							cells : [
								{},
								{},
							]
						},
					],
				},
				XXXdgEditorLayout2 : {
					_uid : 'layout-1',
					sections : [
						{
							_uid : 'section-1',
							grids : [
								{
									_uid : 'grid-1',
									cells : [
										{ _uid : 'cell-1', },
										{ _uid : 'cell-2', },
										{ _uid : 'cell-3', },
									],
								},
								{
									_uid : 'grid-2',
									cells : [
										{ _uid : 'cell-4', },
										{ _uid : 'cell-5', },
									],
								},
							],
						},
						{
							_uid : 'section-2',
							grids : [
								{
									_uid : 'grid-3',
									cells : [
										{ _uid : 'cell-6', },
									],
								},
							],
						},
					],
				},
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			onSlideToggleStateChange( e ){
				//console.log('onSlideToggleStateChange', e)
			},
			XXXfetchAll( finalCallback ){
				let thingsToLoad = 1

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				// fetch pages
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/wp/v2/pages',
					params : {},
					callback : (response) => {
						this.posts = response.data
						this.isContentReady = true
					},
				})
			},
		},
		mounted() {
			/*
			this.fetchAll(()=>{
				//console.log( this.$options.name, '• fetchAll() • finalCallback()')
				//this.isContentReady = true
			})
			*/
			EventBus.$on('addCell', (payload)=>{
				console.log('EventBus.$on(addCell)', payload)

				this.dgEditorLayout.rows[payload.rowIndex].cells.push( {} )
			})

			this.isContentReady = true
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgButton {
		/*
		&__inner { background-color: fade(green, 15); }
		&__icon { outline: 1px solid fade(green, 50); }
		&__text { outline: 1px solid fade(green, 50); }
		*/
	}

    .DevView { // debug
        [showBorders2] & {}
    }
    .DevView { // layout
    }
    .DevView { // styling
    }

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
