<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgEditor></DgEditor>

	2022-03-14	init

-->

<template>
	<div class="DgEditor" :class="elmClasses">
		<div class="DgEditor__inner">
			DgEditor<br/>

			<DgEditorRow v-for="(row, rIndex) in _.get( layout, 'rows' )" :key="rIndex" :row="row" :rowIndex="rIndex">
				<!--
				<pre name="row">{{row}}</pre>
				-->
				<DgEditorCell v-for="(cell, cIndex) in _.get( row, 'cells' )" :key="cIndex" :row="row" :cell="cell" />
			</DgEditorRow>

			<pre name="layout">{{layout}}</pre>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import DgEditorRow from '@/components/editor/DgEditorRow.vue'
	import DgEditorCell from '@/components/editor/DgEditorCell.vue'

	export default {
		name: 'DgEditor',
		components: {
			DgEditorRow,
			DgEditorCell,
		},
		mixins: [],
		props: {
			layout: {
				type     : [Object, Boolean],
				default  : ()=>{ return {} },
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgEditor { // debug
		[showBorders2] & {}
	}
	.DgEditor { // layout
	}
	.DgEditor { // styling
		padding: 10px;
		background-color: darken( white, 35 );
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
