/**
 * Diese Componente ist für folgende Aufgaben zuständig:
 *
 * – sie registriert das mhPageBuilder-Vuex-Store-Module
 * – mapped alle getters, mutations and actions
 * – empfängt alle events die via EventBus emitted werden und verändert entsprechend den state
 *
 */

import EventBus from '@/helper/EventBus.js'
import store from "../store/store.js";
import { mapState, mapGetters, mapMutations } from 'vuex'
import { clone } from 'lodash'

export default {
	props: {},
	data() {
		return {};
	},
	watch: {},
	computed: {
		...mapGetters({
			selectedItemsIds : 'mhPageBuilder/selectedItemsIds',
			pages : 'mhPageBuilder/pages',
			getPage : 'mhPageBuilder/getPage',
			isItemSelected : 'mhPageBuilder/isItemSelected',
			isItemHovered : 'mhPageBuilder/isItemHovered',
		}),
		...mapState({
			state : 'mhPageBuilder',
		}),
	},
	methods: {
		...mapMutations({
			selectItem : 'mhPageBuilder/selectItem',
			unselectItem : 'mhPageBuilder/unselectItem',
			unselectAllItems : 'mhPageBuilder/unselectAllItems',
		}),
	},
	created(){
		const alreadyRegistered = this.$store.state['mhPageBuilder'] ? true : false
		if( !alreadyRegistered ) this.$store.registerModule("mhPageBuilder", store)
	},
	mounted(){
		EventBus.$on('mhPageBuilder/addLayoutItem', ( payload, doLog = true ) => {
			// groupCollapsed group
			if( doLog ){
				console.groupCollapsed( 'EventBus • mhPageBuilder/addLayoutItem' )
				console.log('payload:', payload)
				/*
				console.log('layoutId:', layoutId)
				console.log('path:', path)
				console.log('type:', type)
				console.log('id:', id)
				*/
				console.groupEnd()
			}
		})
		EventBus.$on('mhPageBuilder/containerClick', ( payload, doLog = true ) => {
			const e = payload.event
			const pageId = payload.pageId
			const path = payload.path
			const item = payload.item
			const itemId = item.id
			const itemType = item.type
			const itemWasSelected = this.isItemSelected( itemId )
			const isModifierKeypressed = e.metaKey || e.ctrlKey ? true : false // clear previous selection if modifier key is not pressed
			const appendToSelection = isModifierKeypressed
			const logStyle = 'background-color: lightblue; color: black;'

			// groupCollapsed group
			if( doLog ){
				console.groupCollapsed( '%c' + 'EventBus • mhPageBuilder/containerClick', logStyle, itemId )
				console.log('payload:', payload)
				console.log('------')
				console.log('item:', item)
				console.log('path:', path)
				console.log('pageId:', pageId)
				console.log('itemWasSelected:', itemWasSelected)
				console.log('isModifierKeypressed:', isModifierKeypressed)
				console.groupEnd()
			}

			// layouts can't be selected < ist das wirklich eine gute idee?
			// am schluss ja nur die id nicht im selectedItemsIds array auftauchen, mmmh
			if( itemType === 'layout' ){
				//this.unselectAllItems()
				this.unselectAllItems()
				return
			}

			// remove item from selection if it was already selected
			if( itemWasSelected ){
				// just remove from selection
				if( isModifierKeypressed ){
					//this.unselectItem( item )
					this.unselectItem({
						itemId : itemId
					})
				}
				// if there are other selected: remove them and keep only this item
				else {
					clone( this.selectedItemsIds ).forEach( ( _itemId, index )=>{
						//if( itemId !== _itemId ) this.unselectItem( _itemId )
						if( itemId !== _itemId ) this.unselectItem({
							itemId : _itemId
						})
					})
				}
			}
			// add item to selection if it wasnt already selected
			else {
				//this.selectItem( item, appendToSelection )
				this.selectItem({
					itemId : itemId,
					item : item,
					appendToSelection : appendToSelection,
				})
			}
		})
		EventBus.$on('mhPageBuilder/keydown', ( payload, doLog = true) => {
			const logStyle = 'background-color: lightblue; color: black;'
			const key = payload.event.key
			const withMetaKey = payload.event.metaKey || payload.event.ctrlKey
			let isHandled = false // log only for keys that where handled

			if( ['Escape'].includes( key ) ){
				this.unselectAllItems()
				isHandled = true
			}
			if( ['c'].includes( key ) && withMetaKey ){
				//this.copyItems()
				isHandled = true
			}

			/*
			if( ['Backspace', 'Delete'].includes( key ) ){
				this._.clone( this.selectedItemsIds ).forEach( (_itemId, index)=>{
					this.removeItem( _itemId )
				})
			}
			*/

			// groupCollapsed group
			if( doLog && isHandled ){
				console.groupCollapsed( '%c' + 'EventBus • mhPageBuilder/keydown', logStyle, '• ' + key, withMetaKey )
				console.log('payload:', payload)
				console.log('------')
				console.log('key:', key)
				console.groupEnd()
			}
		})
	},
	destroyed(){},
}
