<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<CellContainer></CellContainer>

	2022-04-06	init

-->

<template>
	<div class="CellContainer" :class="elmClasses">
		<ContainerGui
			:label="'CellContainer • ' + data.id + ' • ' + path + ' • ' + isSelected"
			:isVisibleAddOnTopBtn="false"
			:isVisibleAddOnRightBtn="true"
			:isVisibleAddOnBottomBtn="false"
			:isVisibleAddOnLeftBtn="true"
			:isSelected="isSelected"
			:isVisible="isGuiVisible"
		></ContainerGui>

		<div class="CellContainer__content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'

	import ContainerMixin from '@/components/MhPageBuilder/v1/mixins/Container.mixin.js'
	import ContainerGui from '@/components/MhPageBuilder/v1/ContainerGui.vue'

	export default {
		name: 'CellContainer',
		components: {
			ContainerGui,
		},
		mixins: [
			ContainerMixin,
		],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			myProp: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.CellContainer { // debug
		[showBorders2] & {}
	}
	.CellContainer { // vars
		--gui-primaryColor : #9C89FE;
	}
	.CellContainer { // layout
		position: relative;
	}
	.CellContainer { // styling
		&__content { padding: 20px; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
