//import inViewportMixin from './vue-in-viewport-mixin'
import EventBus from '@/helper/EventBus.js'
import store from "../store/store.js";
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
	props: {},
	data() {
		return {};
	},
	watch: {},
	computed: {
		...mapGetters({
			isValid : 'mhPageBuilder/isValid',
			isItemValid : 'mhPageBuilder/isItemValid',
			getPage : 'mhPageBuilder/getPage',
		}),
		...mapState({
			state : 'mhPageBuilder',
		}),
	},
	methods: {
		XXXXsomeFunction( doLog = true ){
			const layoutId = this.layoutId
			const path = this.path
			const type = this.data.type
			const id = this.data.id

			// groupCollapsed group
			if( doLog ){
				console.groupCollapsed( this.$options.name, '• onClick()' )
				console.log('layoutId:', layoutId)
				console.log('path:', path)
				console.log('type:', type)
				console.log('id:', id)
				console.groupEnd()
			}
		},
	},
	created(){
		const alreadyRegistered = this.$store.state['mhPageBuilder'] ? true : false
		if( !alreadyRegistered ) this.$store.registerModule("mhPageBuilder", store)
	},
	mounted(){
		console.log('Store.mixin.js • mounted')
	},
	destroyed(){},
}
