<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ContainerGui></ContainerGui>

	2021-09-24	init

-->

<template>
	<div class="ContainerGui" :class="elmClasses">
		<div class="ContainerGui__label" v-html="label"></div>
		<div class="ContainerGui__border"></div>
		<div class="ContainerGui__rect"></div>
		<div class="ContainerGui__selectedBorder"></div>
		<!--
		-->
		<a class="ContainerGui__btn ContainerGui__btn--addOnTop" role="button" v-if="isVisibleAddOnTopBtn" @click.stop="emitAddLayoutItem('before')">+</a>
		<a class="ContainerGui__btn ContainerGui__btn--addOnRight" role="button" v-if="isVisibleAddOnRightBtn" @click.stop="emitAddLayoutItem('after')">+</a>
		<a class="ContainerGui__btn ContainerGui__btn--addOnBottom" role="button" v-if="isVisibleAddOnBottomBtn" @click.stop="emitAddLayoutItem('after')">+</a>
		<a class="ContainerGui__btn ContainerGui__btn--addOnLeft" role="button" v-if="isVisibleAddOnLeftBtn" @click.stop="emitAddLayoutItem('before')">+</a>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'ContainerGui',
		components: {},
		mixins: [],
		props: {
			label: {
				type     : [String],
				default  : 'Label',
				required : false,
			},
			isVisibleAddOnTopBtn: {
				type     : [Boolean],
				default  : true,
				required : false,
			},
			isVisibleAddOnRightBtn: {
				type     : [Boolean],
				default  : true,
				required : false,
			},
			isVisibleAddOnBottomBtn: {
				type     : [Boolean],
				default  : true,
				required : false,
			},
			isVisibleAddOnLeftBtn: {
				type     : [Boolean],
				default  : true,
				required : false,
			},
			isSelected: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			isHovered: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			isVisible: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isVisible ) classes.push( this.$options.name + '--isVisible')
				if( this.isHovered ) classes.push( this.$options.name + '--isHovered')
				if( this.isSelected ) classes.push( this.$options.name + '--isSelected')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
			emitAddLayoutItem( where, doLog = false ){
				const pageId = this.$parent.pageId
				const parentIndex = this.$parent.index
				const type = this.$parent.data.type
				const index = where === 'before' ? parentIndex : parentIndex + 1
				const addTo = this.$parent.parentPath

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• emitAddItem( where )' )
					console.log('where:', where)
					console.log('------')
					console.log('pageId:', pageId)
					console.log('type:', type)
					console.log('addTo:', addTo)
					console.log('index:', index)
					console.groupEnd()
				}

				EventBus.$emit('mhPageBuilder/addLayoutItem', {
					pageId : pageId,
					type : type,
					addTo : addTo,
					index : index,
				})
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ContainerGui { // debug
		[showBorders2] & {}
	}
	.ContainerGui { // vars
		--primaryColor : var( --gui-primaryColor, red );
		--btn-height : 20px;
		--border-weight : 1px;
		--selectedBorder-weight : 2px;
	}
	.ContainerGui { // layout
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;

		&__label {
			position: absolute;
			top: 0; left: 0;
			pointer-events: none;
		}
		&__rect, &__border, &__selectedBorder {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			pointer-events: none;
		}
		&__border, &__selectedBorder {
			//inset: -0.5px;
			inset: calc( var(--border-weight) * -0.5 );
		}
		&__selectedBorder {
			inset: calc( var(--selectedBorder-weight) * -0.5 );
		}

		&__btn {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			height: var(--btn-height); width: var(--btn-height);
			z-index: 2;
		}
		&__btn--addOnTop { top: calc( 0% - var(--btn-height)*0.5 ); left: calc( 50% - var(--btn-height)*0.5 ); }
		&__btn--addOnRight { top: calc( 50% - var(--btn-height)*0.5 ); left: calc( 100% - var(--btn-height)*0.5 ); }
		&__btn--addOnBottom { top: calc( 100% - var(--btn-height)*0.5 ); left: calc( 50% - var(--btn-height)*0.5 ); }
		&__btn--addOnLeft { top: calc( 50% - var(--btn-height)*0.5 ); left: calc( 0% - var(--btn-height)*0.5 ); }
	}
	.ContainerGui { // styling
		background-color: white;
		user-select: none;
		//opacity: 0.25;
		//pointer-events: none;

		&--isHovered &__border { border: var(--border-weight) dotted blue; z-index: 2; }
		&--isSelected &__selectedBorder { border: var(--selectedBorder-weight) solid blue; z-index: 2; }
		//&--isHovered { opacity: 1; pointer-events: auto; }
		//&--isVisible { opacity: 1; pointer-events: auto; }

		&__label { font-size: 12px; line-height: 1em; padding: 0.25em; color: var(--primaryColor); }
		&__rect { background-color: var(--primaryColor); opacity: 0.15; }
		&__border { border: var(--border-weight) solid var(--primaryColor); }
		&__selectedBorder { outline: var(--border-weight) solid transparent; }

		&__btn {
			border-radius: 50%;
			border: 1px solid var(--primaryColor);
			background-color: var(--primaryColor);
			color: white;
			//opacity: 0;
			//display: none;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
