<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LayoutContainer></LayoutContainer>

	2022-04-06	init

-->

<template>
	<div class="LayoutContainer" :class="elmClasses">
		<ContainerGui
			:label="'LayoutContainer'"
			:isVisibleAddOnTopBtn="false"
			:isVisibleAddOnRightBtn="false"
			:isVisibleAddOnBottomBtn="false"
			:isVisibleAddOnLeftBtn="false"
		></ContainerGui>

		<div class="LayoutContainer__content">
			<slot></slot>
		</div>

		<!--
		<div style="display: flex;">
			<div style="width: 100%">
				<pre name="layout" maxheight>{{layout}}</pre>
			</div>
			<div style="width: 100%">
				<button style="position: relative" @click="selectedItemsIds = []">Clear Selection</button>
				<pre name="selectionLevel">{{selectionLevel}}</pre>
				<pre name="hoveredItemsIds">{{hoveredItemsIds}}</pre>
				<pre name="selectedItemsIds">{{selectedItemsIds}}</pre>
			</div>
		</div>
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import ContainerMixin from './mixins/Container.mixin.js'
	import ContainerGui from './ContainerGui.vue'

	export default {
		name: 'LayoutContainer',
		components: {
			ContainerGui,
		},
		mixins: [
			ContainerMixin,
		],
		props: {
			pageId: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ){
					//this.items = this.getItemChilds( to )
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			addItem( payload, doLog = true ){
				const newItemType = payload.type
				const addToArr = this._.get( this.layout, payload.addTo )
				const atIndex = payload.index
				const logStyle = 'background-color: yellow; color: black;'

				let newItem = undefined
				const newSectionItem = {
					id : 'section-' + this._.random( 100, 999 ),
					type : 'section',
					grids : [],
				}
				const newGridItem = {
					id : 'grid-' + this._.random( 100, 999 ),
					type : 'grid',
					cells : [],
				}
				const newCellItem = {
					id : 'cell-' + this._.random( 100, 999 ),
					type : 'cell',
					modules : [],
				}

				newGridItem.cells.push( newCellItem )
				newSectionItem.grids.push( newGridItem )

				if( 'section' === newItemType ){ newItem = newSectionItem }
				if( 'grid' === newItemType ){ newItem = newGridItem }
				if( 'cell' === newItemType ){ newItem = newCellItem }

				// add new item to layout obj
				addToArr.splice( atIndex, 0, newItem )

				// add new item to selectedItemsIds
				this.selectItem( newItem )

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed('%c' + this.$options.name + ' • addItem( payload )', logStyle )
					console.log('payload:', payload)
					console.log('------')
					console.log('addToArr:', addToArr)
					console.log('atIndex:', atIndex)
					console.log('newItem:', newItem)
					console.groupEnd()
				}
			},
			removeItem( itemOrItemId, doLog = true ){
				const item = this._.isString( itemOrItemId ) ? this.getItemById( itemOrItemId ) : itemOrItemId
				const itemId = item.id
				const logStyle = 'background-color: yellow; color: black;'

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed('%c' + this.$options.name + ' • removeItem( itemOrItemId )', logStyle, itemId )
					console.log('itemOrItemId:', itemOrItemId)
					console.log('---------')
					console.log('item:', item)
					console.log('itemId:', itemId)
					/*
					*/
					console.groupEnd()
				}
			},
			selectItem( itemOrItemId, appendToSelection = false, doLog = true ){
				const itemId = this._.isObject( itemOrItemId ) ? this._.get( itemOrItemId, 'id') : itemOrItemId
				const itemType =itemId.split('-')[0]
				const logStyle = 'background-color: yellow; color: black;'

				// unselect previous selected
				if( !appendToSelection ){
					this.unselectAllItems()
				}

				// select item
				this.selectedItemsIds.push( itemId )

				// remove items that are not from the same type
				this._.clone( this.selectedItemsIds ).forEach( ( _itemId )=>{
					const _itemType = _itemId.split('-')[0]
					if( itemType !== _itemType ) this.unselectItem( _itemId )
				})

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( '%c' + this.$options.name + ' • selectItem( itemOrItemId, appendToSelection )', logStyle, itemId, appendToSelection,  )
					console.log('itemOrItemId:', itemOrItemId)
					console.log('appendToSelection:', appendToSelection)
					console.log('---------')
					console.log('itemId:', itemId)
					console.groupEnd()
				}
			},
			unselectItem( itemOrItemId, doLog = true ){
				const itemId = this._.isObject( itemOrItemId ) ? this._.get( itemOrItemId, 'id') : itemOrItemId
				const itemIndex = this.selectedItemsIds.indexOf( itemId )
				const logStyle = 'background-color: yellow; color: black;'

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( '%c' + this.$options.name + ' • unselectItem( itemOrItemId )', logStyle, itemId )
					console.log('itemOrItemId:', itemOrItemId)
					console.log('------')
					console.log('itemId:', itemId)
					console.log('itemIndex:', itemIndex)
					console.groupEnd()
				}

				this.selectedItemsIds.splice( itemIndex, 1 )
			},
			unselectAllItems( doLog = true ){
				const logStyle = 'background-color: yellow; color: black;'

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( '%c' + this.$options.name + ' • unselectAllItems()', logStyle )
					console.groupEnd()
				}

				this.selectedItemsIds = []
			},

			isContainerGuiVisible( section = false, grid = false, cell = false ){
				return true
			},
			getItemById( itemId, doLog = true ){
				const logStyle = 'background-color: yellow; color: black;'
				const item = this._.find( this.items, { id : itemId } )

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( '%c' + this.$options.name + ' • getItemById( itemId )', logStyle, itemId )
					console.log('itemId:', itemId)
					console.log('---------')
					console.log('this.items:', this.items)
					console.log('item:', item)
					/*
					*/
					console.groupEnd()
				}

				return item
			},
			getItemChilds( item, doLog = true ){
				const itemType = item.type
				let childs = []

				if( itemType === 'layout' ){
					item.sections.forEach((section)=>{
						childs.push( section )
						section.grids.forEach((grid)=>{
							childs.push( grid )
							grid.cells.forEach((cell)=>{
								childs.push( cell )
								cell.modules.forEach((module)=>{
									childs.push( module )
								})
							})
						})
					})
				}
				if( itemType === 'section' ){
					item.grids.forEach((grid)=>{
						childs.push( grid )
						grid.cells.forEach((cell)=>{
							childs.push( cell )
							cell.modules.forEach((module)=>{
								childs.push( module )
							})
						})
					})
				}
				if( itemType === 'grid' ){
					item.cells.forEach((cell)=>{
						childs.push( cell )
						cell.modules.forEach((module)=>{
							childs.push( module )
						})
					})
				}
				if( itemType === 'cell' ){
					item.modules.forEach((module)=>{
						childs.push( module )
					})
				}

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• getItemChilds( item )' )
					console.log('item:', item)
					console.log('------')
					console.log('itemType:', itemType)
					console.log('childs:', childs)
					console.groupEnd()
				}

				return childs
			},
			onContainerClick( e, layout = false, section = false, grid = false, cell = false, doLog = true ){
				const item = cell ? cell : grid ? grid : section ? section : layout ? layout : {}
				const itemId = item.id
				const itemType = item.type
				const itemWasSelected = this.selectedItemsIds.includes( itemId )
				const isModifierKeypressed = e.metaKey || e.ctrlKey ? true : false // clear previous selection if modifier key is not pressed
				const appendToSelection = isModifierKeypressed
				const logStyle = 'background-color: lightblue; color: black;'

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( '%c' + this.$options.name + ' • onContainerClick()', logStyle, itemId, isModifierKeypressed )
					console.log('item:', item)
					console.log('------')
					console.log('itemWasSelected:', itemWasSelected)
					console.log('isModifierKeypressed:', isModifierKeypressed)
					console.groupEnd()
				}

				// layouts cann't be selected < ist das wirklich eine gute idee?
				// am schluss ja nur die id nicht im selectedItemsIds array auftauchen, mmmh
				if( itemType === 'layout' ){
					this.unselectAllItems()
					return
				}

				// remove item from selection if it was already selected
				if( itemWasSelected ){
					// just remove from selection
					if( isModifierKeypressed ){
						this.unselectItem( item )
					}
					// if there are other selected:
					// remove them and keep only this item
					else {
						this._.clone( this.selectedItemsIds).forEach( (_itemId, index)=>{
							if( itemId !== _itemId ) this.unselectItem( _itemId )
						})
					}
				}
				// add item to selection if it wasnt already selected
				else {
					this.selectItem( item, appendToSelection )
				}
			},
			onContainerMouseEnter( e, layout = false, section = false, grid = false, cell = false ){
				const container = cell ? cell : grid ? grid : section ? section : layout ? layout : {}
				const containerId = container.id
				const hoveredIndex = this.hoveredItemsIds.indexOf( containerId )

				//console.log('onContainerMouseEnter( container )', container.id, container, hoveredIndex)

				//--if( hoveredIndex === -1 ) this.hoveredItemsIds.push( containerId )
				//this.hoveredItemsIds.push( containerId )
			},
			onContainerMouseLeave( e, layout = false, section = false, grid = false, cell = false ){
				const container = cell ? cell : grid ? grid : section ? section : layout ? layout : {}
				const containerId = container.id
				const hoveredIndex = this.hoveredItemsIds.indexOf( containerId )

				//console.log('onContainerMouseLeave( container )', container.id, container, hoveredIndex)

				//if( hoveredIndex > -1 ) this.hoveredItemsIds.splice( hoveredIndex, 1 )
			},
		},
		created(){

		},
		mounted(){
			EventBus.$on('XXXaddItem', ( payload, doLog = true )=>{
				const logStyle = 'background-color: lightblue; color: black;'

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed('%cEventBus.$on("addItem", payload)', logStyle)
					console.log('payload:', payload)
					console.groupEnd()
				}

				this.addItem( payload )
			})
			EventBus.$on('XXXselectItem', ( item, doLog = true )=>{
				const logStyle = 'background-color: lightblue; color: black;'

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed('%cEventBus.$on("selectItem", item)', logStyle)
					console.log('item:', item)
					console.groupEnd()
				}

				this.selectItem( item )
			})
			EventBus.$on('XXXunselectItem', (item, doLog = true )=>{
				const logStyle = 'background-color: lightblue; color: black;'

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed('%cEventBus.$on("unselectItem", item)', logStyle)
					console.log('item:', item)
					console.groupEnd()
				}

				this.unselectItem( item )
			})
		},
		destroyed(){
			window.removeEventListener('keydown', this.onKeyydown )
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.LayoutContainer { // vars
		--gui-primaryColor: #684D07;
	}
	.LayoutContainer { // debug
		[showBorders2] & {}
	}
	.LayoutContainer { // layout
		position: relative;
	}
	.LayoutContainer { // styling
		&__content { padding: 20px; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
