import { find, difference } from 'lodash'

const XXXgetItemChilds = ( item, doLog = true ) => {
	const itemType = item.type
	let childs = []
	const logStyle = 'background-color: green; color: black;'

	if( itemType === 'layout' ){
		item.sections.forEach((section)=>{
			childs.push( section )
			section.grids.forEach((grid)=>{
				childs.push( grid )
				grid.cells.forEach((cell)=>{
					childs.push( cell )
					cell.modules.forEach((module)=>{
						childs.push( module )
					})
				})
			})
		})
	}
	if( itemType === 'section' ){
		item.grids.forEach((grid)=>{
			childs.push( grid )
			grid.cells.forEach((cell)=>{
				childs.push( cell )
				cell.modules.forEach((module)=>{
					childs.push( module )
				})
			})
		})
	}
	if( itemType === 'grid' ){
		item.cells.forEach((cell)=>{
			childs.push( cell )
			cell.modules.forEach((module)=>{
				childs.push( module )
			})
		})
	}
	if( itemType === 'cell' ){
		item.modules.forEach((module)=>{
			childs.push( module )
		})
	}

	// groupCollapsed group
	if( doLog ){
		console.group('%c' + 'store.js • getItemChilds( item )', logStyle)
		console.log('item:', item)
		console.log('------')
		console.log('itemType:', itemType)
		console.log('childs:', childs)
		console.groupEnd()
	}

	return childs
}
const XXXgetItemById = ( itemId, doLog = true )=>{
	const logStyle = 'background-color: yellow; color: black;'
	const item = this._.find( this.items, { id : itemId } )

	// groupCollapsed group
	if( doLog ){
		console.group('%c' + 'store.js • getItemById( itemId )', logStyle)
		console.log('itemId:', itemId)
		console.log('---------')
		console.log('this.items:', this.items)
		console.log('item:', item)
		/*
		*/
		console.groupEnd()
	}

	return item
}

export default {
	namespaced: true,
	state: {
		pages : [
			{	id : 'page-1',
				type : 'page',
				layouts : [
					{	id : 'layout-1',
						type : 'layout',
						sections : [
							{	id : 'section-1',
								type : 'section',
								grids : [
									{
										id : 'grid-1',
										type : 'grid',
										cells : [
											{ id : 'cell-1', type : 'cell', modules : [], },
											{ id : 'cell-2', type : 'cell', modules : [],},
											{ id : 'cell-3', type : 'cell', modules : [],},
										],
									},
									{
										id : 'grid-2',
										type : 'grid',
										cells : [
											{ id : 'cell-4', type : 'cell', modules : [], },
											{ id : 'cell-5', type : 'cell', modules : [], },
										],
									},
								],
							},
							{	id : 'section-2',
								type : 'section',
								grids : [
									{
										id : 'grid-3',
										type : 'grid',
										cells : [
											{ id : 'cell-6', type : 'cell', modules : [], },
										],
									},
								],
							},
							{	id : 'section-3',
								type : 'section',
								grids : [
									{
										id : 'grid-4',
										type : 'grid',
										cells : [
											{ id : 'cell-7', type : 'cell', modules : [], },
											{ id : 'cell-8', type : 'cell', modules : [], },
										],
									},
								],
							},
						],
					},
				],
			},
			{	id : 'page-2',
				type : 'page',
				layouts : [],
			},
		],
		hoveredItemsIds : [],
		selectedItemsIds : [
			'section-1',
			'section-2',
		],
		history : [],
	},
	getters : {
		pages: ( state, getters ) => {
			return state.pages
		},
		selectedItemsIds: ( state, getters ) => {
			return state.selectedItemsIds
		},
		getPage: ( state, getters ) => ( pageId )=>{
			return find( state.pages, { id : pageId } ) //.filter((o)=>{ return o.pageId === pageId})
		},
		isItemSelected: ( state, getters ) => ( itemId )=>{
			return state.selectedItemsIds.includes( itemId )
		},
		isItemHovered: ( state, getters ) => ( itemId )=>{
			return state.hoveredItemsIds.includes( itemId )
		},
	},
	mutations: {
		selectItem( state, payload, doLog = true ) {
			const logStyle = 'background-color: green; color: black;'
			const item = payload.item
			const itemId = payload.itemId
			const itemType = payload.item.type
			const appendToSelection = payload.appendToSelection

			// unselect all previous selected
			if( !appendToSelection ) state.selectedItemsIds = []

			// select the item
			state.selectedItemsIds.push( itemId )

			// remove items that are not from the same type
			const removeSelectedIds = []
			state.selectedItemsIds.forEach( ( _itemId )=>{
				const _itemType = _itemId.split('-')[0]
				//if( item.type !== _itemType ) this.unselectItem( _itemId )
				if( itemType !== _itemType ) removeSelectedIds.push( _itemId )
			})
			state.selectedItemsIds = difference( state.selectedItemsIds, removeSelectedIds )

			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('%c' + 'store.js • selectItem()', logStyle, itemId)
				console.log('state:', state)
				console.log('payload:', payload)
				console.log('------')
				console.log('item:', item)
				console.log('itemId:', itemId)
				console.log('itemType:', itemType)
				console.log('appendToSelection:', appendToSelection)
				console.log('------')
				console.log('removeSelectedIds:', removeSelectedIds)
				console.log('state.selectedItemsIds:', state.selectedItemsIds)
				console.groupEnd()
			}
		},
		unselectItem( state, payload, doLog = true ) {
			const logStyle = 'background-color: green; color: black;'
			const itemId = payload.itemId
			const itemSelectedIndex = state.selectedItemsIds.indexOf( itemId )

			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('%c' + 'store.js • unselectItem()', logStyle, itemId)
				console.log('state:', state)
				console.log('payload:', payload)
				console.log('------')
				console.log('itemId:', itemId)
				console.log('itemSelectedIndex:', itemSelectedIndex)
				console.groupEnd()
			}

			state.selectedItemsIds.splice( itemSelectedIndex, 1 )
		},
		unselectAllItems( state, payload, doLog = true ) {
			const logStyle = 'background-color: green; color: black;'

			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('%c' + 'store • unselectAllItems()', logStyle)
				console.log('state:', state)
				console.log('payload:', payload)
				console.groupEnd()
			}

			state.selectedItemsIds = []
		},
	},
	actions: {}
}
